<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import BasicInfo from "@/components/loyalty/campaign-basic-info"
import helpers from '../../../helpers'

import {
} from "@/state/helpers";

export default {
  page: {
    title: "Campaigns",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    BasicInfo
  },
  data() {
    return {
      title: "Campaigns",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      campaigns:[],
      confirmDelete:false,
      campaign: {},
      empty_list_config:{
          "title" : 'No se encontraron campañas',
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : 'No se encontraron campañas',
          "subtitle" : 'Aún no has creado campañas. Comienza creando una nueva.',
          "buttonText" : 'Nueva Campaña',
          icon: "fa-cubes"
      },
      isLocal: window.config.env == 'dev',
      showNewCampaignModal: false,
      query:''
    };
  },
  created(){
		this.debounceCampaigns = _debounce(this.searchCampaigns.bind(this), 1000);
	},
	mounted(){
		this.loadCampaigns();
  },
  methods:{
    ...helpers,

    onRemoveCampaign(data){
      this.campaign = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveCampaignClicked(){
      let loader = this.$loading.show();
      this.currentPage=1;
        this.loadCampaigns();
      this.$notify({ type: 'success', text: this.$t('personalizations.delete_personalization_success'),title:  this.$t('personalizations.title') });
      loader.hide();
    },

		loadCampaigns(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.campaigns = [
        {
          _id: '1',
          name: '10 puntos por compra en APP',
          startDate: new Date(),
          endDate: null, 
          createdAt: new Date(),
          status: 1,
          trigger: {id:1, label: 'Cliente realiza una compra'}
        }
      ]
      this.isLoading = false;
      loader.hide();
    },
    searchCampaigns(query){
      this.query = query;
			/*let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("memberships.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.memberships=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getContents(params).then((contents)=>{
          if(contents&& contents.data){
            this.personalizations= contents.data;
            this.rows= contents.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalizations();
      }*/
    },
		onCampaignListPageClicked(){
			this.loadCampaigns();
		},	
    onEditCampaignClicked(data){
      this.campaign = data;
      this.$router.push({
            path: `/loyalty-campaign?id=${data._id}`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadCampaigns();
    },
    onNewCampaignClicked(){
      this.campaign = {},
      this.showNewCampaignModal = true;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Campañas" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceCampaigns($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewCampaignClicked">
                  <i class="mdi mdi-plus me-1"></i> Nueva Campaña
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && campaigns.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && campaigns.length == 0 && query ==''" @onButtonClicked="onNewCampaignClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && campaigns.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Fecha Creación</th>
                      <th scope="col">Activo Desde</th>
                      <th scope="col">Activo Hasta</th>
                      <th scope="col">Status</th>
                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in campaigns" :key="data._id">
                      <td v-on:click="onEditCampaignClicked(data)">
                        <h5 class="text-truncate font-size-14 mb-0 interact">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                        <p class="text-muted mb-0 small interact">{{data.trigger.label}}</p>
                      </td>
                      <td>
												{{ `${parseDateWithLocale(data.createdAt, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(data.createdAt, {hour: '2-digit', minute:'2-digit'})}`}}
											</td>
                      <td>
												{{ `${parseDateWithLocale(data.startDate, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(data.startDate, {hour: '2-digit', minute:'2-digit'})}`}}
											</td>
                      <td>
												{{ data.endDate ? `${parseDateWithLocale(data.endDate, {year: 'numeric', month: 'short', day: 'numeric'})} ${parseTimeWithLocale(data.endDate, {hour: '2-digit', minute:'2-digit'})}` : '-' }}
											</td>
											<td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? 'Activo' : 'Inactivo' }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditCampaignClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveCampaign(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onCampaignListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveCampaignClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>Estás seguro que deseas eliminar esta campaña?</p>
        </b-modal>
        <b-modal
          v-model = "showNewCampaignModal"
          title-class="font-18"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          :title="'Nueva Campaña'">
          <BasicInfo :campaign="campaign"></BasicInfo>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
